<template>
  <b-row>


    <b-modal
      id='modal-login'
      cancel-variant='outline-secondary'
      ok-title='Saqalash'
      cancel-title='Bekor qilish'
      centered
      title='Foydalanuvchi tahrirlash'
      @ok='submitEditForm'
    >
      <b-form @submit.prevent='submitEditForm'>
        <b-form-group>
          <label for='username'>Login:</label>
          <b-form-input
            id='username'
            v-model='editedUser.username'
            placeholder='Login kiriting'
          />
        </b-form-group>
        <b-form-group>
          <label for='fullName'>FIO:</label>
          <b-form-input
            id='fullName'
            v-model='editedUser.fullName'
            placeholder='FIO kiriting'
          />
        </b-form-group>

        <b-form-group>
          <label for='password'>Parol:</label>
          <b-form-input
            id='password'
            v-model='editedUser.password'
            type='password'
            placeholder='Parolni kiriting'
          />
        </b-form-group>

        <b-form-group>
          <label for='phoneNumber'>Telefon raqam:</label>
          <b-form-input
            id='phoneNumber'
            v-model='editedUser.phoneNumber'
            type='tel'
            placeholder='Telefon raqam kiriting'
          />
        </b-form-group>
        <b-form-group>
         <div> <label for='role'>Role:</label></div>
          <el-select style='width: 100%' id='role' v-model="selectedRoles" multiple placeholder="Tanlang">
            <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </b-form-group>
      </b-form>
    </b-modal>
    <div>
    </div>
    <b-col
      cols='12'
      md='12'
    >
      <b-card title='Foydalanuvchilar'>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-login
          class='mb-2'
          @click='openAddModal'
          variant='outline-primary'
        >
          Foydalanuvchi qo'shish
        </b-button>
        <b-table
          responsive='sm'
          :items='items'
          :fields='fields'
          :current-page='currentPage'
        >
          <template #cell(roles)='data'>
            <span v-for='role in data.item.roles' :key='role.id' class='badge badge-light-primary'>
              {{ role.name }}
            </span>
          </template>
          <template #cell(editButton)='data'>
            <div class='edit-button-container cursor-pointer' @click='openEditModal(data.item.id)'>
              <feather-icon
                icon='EditIcon'
                class='mr-75'
                size='18'
                @mouseover='handleMouseOver'
                @mouseout='handleMouseOut'
              />
              Tahrirlash
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model='currentPage'
          :total-rows='totalRows'
          :per-page='perPage'
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'

import {
  BButton,
  BModal,
  BTable,
  BPagination,
  VBModal,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import routeList from '@/router/routeList'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BTable,
    BPagination,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      editedUserId: null,
      selected: { title: 'Square' },
      editedUser: {
        fullName: '',
        password: '',
        phoneNumber: '',
        username: '',
        roleIds: [],
        isDeleted: null
      },
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'fullName', label: 'FIO', sortable: true },
        { key: 'username', label: 'Login', sortable: true },
        { key: 'phoneNumber', label: 'Telefon raqami', sortable: true },
        { key: 'roles', label: 'Rollari', sortable: true },
        { key: 'editButton', label: 'Tahrirlash' }
      ],
      roles: [],
      selectedRoles:[],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      users: [],
      items: []
    }
  },
  watch: {
    currentPage: 'getUsers'
  },
  created() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    getUsers() {
      const currentPage = this.currentPage - 1;
      this.$http
        .post(`/user/find-all?page=${currentPage}&size=${this.perPage}`)
        .then(response => {
          this.totalRows = response.data.totalElements;
          this.items = response.data.content;
        })
        .catch(error => {
          console.error('Axios error:', error)
        })
    },
    getRoles() {
      this.$http
        .get(`/role/get-all`)
        .then(response => {
          this.roles = response.data;
        })
    },
    openAddModal(){
      this.editedUser ={};
      this.selectedRoles =[];
    },
    openEditModal(userId) {
      this.selectedRoles =[];
      const userToEdit = this.items.find(user => user.id === userId);
      this.editedUserId = userId;
      this.editedUser = { ...userToEdit };
      this.$bvModal.show('modal-login');
      this.editedUser.roles.map((item,index)=>{
        this.selectedRoles.push(item.id);
      });
    },
    submitEditForm() {
      this.editedUser.roleIds = this.selectedRoles;
      this.$http
        .post(`/user/save`, this.editedUser)
        .then(() => {
          this.editedUser = {};
          this.getUsers();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Foydalanuvchi',
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: 'Ma\'lumot muvaffaqiyatli tahrirlandi !'
            }
          })
          this.$bvModal.hide('modal-login');
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xatolik yuz berdi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ma\'lumotni tahrirlashda xatolik yuz berdi. Qaytadan urinib ko\'ring'
            }
          })
        })
    },
    handleMouseOver(event) {

      event.target.classList.add('hovered-icon');
    },
    handleMouseOut(event) {
      event.target.classList.remove('hovered-icon');
    }
  }


}
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';

.hovered-icon {
  transition: transform 0.25s ease;
  cursor: pointer;
  font-size: 12px;
  color: #7367f0;

}
</style>
