import useJwt from '@/auth/jwt/useJwt'
import axios from 'axios'

const routerView = () => ({
  render(h) {
    return h("router-view");
  }
});

export default [
  {
    path: '/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/',
    name: 'dashboard-full-statistics',
    component: () => import('@/views/dashboard/full-statistics/Dashboard.vue'),
  },
]
