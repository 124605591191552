import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'

// Routes
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import routeList from '@/router/routeList'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import LayoutContentRendererDefault
  from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import App from '@/App.vue'
import pages from './routes/pages'
import Login from '@/views/pages/authentication/Login.vue'
import login from '@/router/routes/login'

Vue.use(VueRouter)
// const authGuard = async (to, from, next) => {
//   try {
//     if (typeof next !== 'function') return
//     const accessToken = useJwt.getToken();
//
//     // const { data } = await axios.get('https://bolajon.mtsh.uz/api/user/internal-me', {
//     //   headers: {
//     //     Authorization: `Bearer ${accessToken}`,
//     //   },
//     // })
//     const { data } = await axios.get('https://bolajon.mtsh.uz/api/user/internal-me', {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     })
//     if (accessToken && typeof data !== 'string') {2
//       next()
//     } else {
//       next({name: 'auth-login'})
//       return
//     }
//   } catch (e) {
//     console.log('Error:', e)
//   }
// }
const authGuard = async (to, from, next) => {
  try {
    if (typeof next !== 'function') return
    const accessToken = useJwt.getToken();

    const { data } = await axios.get('https://bolajon.mtsh.uz/api/user/internal-me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (accessToken && typeof data !== 'string') {
      next(); // Foydalanuvchini davom ettirish
    } else {
      next({ name: 'auth-login' }); // Login sahifasiga yo'naltirish
    }
  } catch (e) {
    console.error('Error:', e);
    next({ name: 'auth-login' }); // Xatolik yuz bersa ham login sahifasiga yo'naltirish
  }
};

const routes = [
  {
    path: '/',
    name: 'AppBreadcrumb',
    component:AppBreadcrumb,
    redirect: "/",
    beforeEnter: authGuard,
    children: routeList
  },
  ...login

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  props: { default: true },
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
// const router1 = new VueRouter({
//   routes
// })
//   router.beforeEach( (to,from,next)=>{
//     try {
//       console.log("keldiyo")
//
//     }catch (e) {
//
//     }
//   })


export default router
